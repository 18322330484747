<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: false}"></v-header>
        </div>
        <div class="article">
            <p style="text-align: center;">
                <span style="font-size: 20px;"><strong>充值服务协议</strong></span>
            </p>
            <p>
                <strong>【首部及导言】</strong>
            </p>
            <p>
                <strong>欢迎您使用深圳市万恒云科技有限公司(简称为:万恒云)包月充值服务!</strong>
            </p>
            <p>
                <strong>为使用万恒云包月充值服务(简称为:本服务)。您应当阅读并遵守《充值服务协议》(简称为:本协议)。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。免除或限制责任条款可能以加粗形式提示您注意。</strong>
            </p>
            <p>
                <strong>除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您对本服务的任何购买或接受赠与等获取行为及登录、查看等任何使用行为即视为您已阅读并同意本协议的约束。</strong>
            </p>
            <p>
                <strong>如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>一、【协议的范围】</strong>
            </p>
            <p>
                1.1【协议适用主体范围】
            </p>
            <p>
                本协议是您与万恒云之间关于您使用本服务所订立的协议。
            </p>
            <p>
                1.2【本服务内容】
            </p>
            <p>
                本协议视为《万恒云服务协议》，若链接地址变更的，则以变更后的链接地址所对应的内容为准:其他链接地址变更的情形，均适用前述约定的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。
            </p>
            <p>
                本协议内容同时包括万恒云可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>二、【关于本服务】</strong>
            </p>
            <p>
                包月充值服务，指按照万恒云的指定方式支付一定包月服务费用之后，用户可以享有由万恒云提供的在大公鸡七星彩软件中的特权服务，简称为:本服务.
            </p>
            <p>
                万恒云可能会根据不同的产品及服务类型，推出不同的包月充值服务，目前，万恒云提供会员的包月充值服务，同时，万恒云也可能会根据用户的需求、产品及服务类型的变化等，对现有包月充值服务种类进行调整以及不断推出新的包月充值服务种类，万恒云也可能会在不同时间推出具体不同的服务内容，以不断完善、优化本服务。具体包月充值服务种类及服务内容以相关服务页面公布，实际提供的内容为准。你可以自行根据需要选择相应服务。
            </p>
            <p>
                您所享有的本服务的具体内容可能会因为级别，是否年费开通期限，您选择的具体服务类别等因素而有不同，通常高级别，开通年费服务，开通期限较长等情况下，您将会享有更多的服务，具体以相关服务页面公布、实际提供的内容为准。
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>三、权利义务</strong>
            </p>
            <p>
                3.1【关于收费】
            </p>
            <p>
                准支付相应费用后，方可使用本服务，此外，您也可以通过接受好友赠送等万恒云认可的其他方式享有，使用本服务。
                本服务是万恒云提供的收费服务，您须在按照本服务的收费标
            </p>
            
            <p>
                <strong>万恒云可能会根据本服务的整体规划，对本服务的收费标准、方式等进行修改和变更，前述修改、变更，万恒云将在相应服务页面进行展示。你若需要获取、使用本服务，请先提前了解清楚当时关于本服务的收费标准、方式等信息。</strong>
            </p>
            <p>
                3.2【服务开通】
            </p>
            <p>
                您应该通过万恒云指定的包括但不限于微信支付、支付宝支付，银联支付等现有方式，今后万恒云指定方式在依约支付一定费用后开通本服务。本服务开通之后，不可进行转让，
            </p>
            <p>
                (1)以营利，经营等非个人使用的目的为自己或他人开通本服务
            </p>
            <p>
                (2)通过任何机器人软件、蜘珠软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务
            </p>
            <p>
                (3)通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务
            </p>
            <p>
                (4)通过非万恒云指定的方式为自己或他人开通本服务
            </p>
            <p>
                (5)通过侵犯万恒云或他人合法权益的方式为自己或他人开通本服务
            </p>
            <p>
                (6)通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。
            </p>
            <p>
                3.3【服务期限】
            </p>
            <p>
                3.3.1本服务的服务期限以您自行选择并支付相应服务费用
            </p>
            <p>
                的期限为准，您也可以登陆大公鸡七星彩手机软件或者本服务的相应页面查询。
            </p>
            <p>
                3.3.2本服务的服务开通最高期限会受到一定限制，具体以万恒云制定的的规则为准。
            </p>
            <p>
                3.4【行为规范】
            </p>
            <p>
                3.4.1【五不准】
            </p>
            <p>
                您在使用本服务时不得利用本服务从事以下行为，包括但不限于:
            </p>
            <p>
                (1)发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽暴力的内容
            </p>
            <p>
                (2)发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容
            </p>
            <p>
                (3)虚构事实、隐瞒真相以误导、欺骗他人;
            </p>
            <p>
                (4)发表、传送、传播广告信息及垃圾信息
            </p>
            <p>
                (5)从事其他违反法律法规，政策及公序良俗、社会公德等的行为。
            </p>
            <p>
                3.4.2【用户禁止行为】
            </p>
            <p>
                本服务仅供您个人使用，除非经万恒云书面许可，您不得进行以下行为:
            </p>
            <p>
                (1)通过任何方式搜售本服务中其他用户的用户名，电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户:
            </p>
            <p>
                (2)通过本服务发布包含广告、宣传、促销等内容的信息:
            </p>
            <p>(3)将本服务再次许可他人使用;</p>
            <p>(4)其他未经万恒云书面许可的行为。</p>
            <p>3.4.3 对自己行为负责!</p>
            <p>
                您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括但不限于您所发表的任何内容以及由此产生的任何后果，您应对本服务中的内容自行加以判断并承担因使用内容而引起的所有风险，包括因对内容的正确性，完整性或实用性的依赖而产生的风险
            </p>
            <p>
                <strong>3.5【服务的变更、中止或终止】</strong>
            </p>
            <p>
                <strong>您充分了解并同意，由于互联网服务的特殊性，万恒云可能会按照相关法规、双方约定或在其</strong>
            </p>
            <p>
                <strong>他必要时，中止或终止向您提供本服务，届时。万恒云会依法保护您的合法权益。</strong>
            </p>
            <p>
                <strong>四、【违约责任】</strong>
            </p>
            <p>
                <strong>4.1 如果万恒云发现或收到他人举报您有违反本协议任何行为的，万恒云有权依法进行独立判断并采取技术手段予以删除、屏蔽或断开相关的信息。同时，万恒云有权视您的行为性质，对您采取包括但不限于暂停或终止部分或全部本服务、中止或终止您对本服务的使用、追究您法律责任等措施，万恒云也无需向您退还任何费用，而由此给您带来的损失(包括但不限于通信中断、相关数据清空、未使用的服务费用作为违约金而归万恒云所有等)，由您自行承担，造成万恒云损失的，您也应予以赔偿。</strong>
            </p>
            <p>
                4.2【对第三方损害的处理】
            </p>
            <p>
                您有违反本协议任何行为，导致任何第三方损害的，您应当独立承担责任:万恒云因此遭受损失的，您也应当一并赔偿。
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>五、【其他】</strong>
            </p>
            <p>
                5.1【协议的生效】
            </p>
            <p>
                <strong>您使用本服务即视为您已阅读并同意受本协议的约束。</strong>
            </p>
            <!-- <p>
                5.2【协议签订地]
            </p>
            <p>
                <strong>本协议签订地为中华人民共和国广东省深圳市龙岗区。</strong>
            </p> -->
            <p>
                <strong>5.2【适用法律】</strong>
            </p>
            <p>
                <strong>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律(不包括冲突法)。</strong>
            </p>
            <p>
                5.3【争议解决】
            </p>
            <p>
                <strong>若您和万恒云之间发生任何纠纷或争议，首先应友好协商解决;协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</strong>
            </p>
            <p>
                5.4【条款标题】
            </p>
            <p>
                本协议所有条款的标题仅为阅读方便，本身并无实际涵义。不能作为本协议涵义解释的依据。
            </p>
            <p>
                5.5【条款效力】
            </p>
            <p>
                本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
            </p>
            <p>
                (正文完)
            </p>
            <p style="text-align: right;">
                <strong>万恒云公司</strong>
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    created(){
    }
}
</script>
<style lang="less" scoped>
    .container{
        .article{
            padding: 0 10px;
            font-size: 16px;
            h2{
                text-align: center;
            }
        }
    }
</style>